import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { sendContact } from '../../api/contacto';
import Swal from 'sweetalert2';

const ContactForm = (props) => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        company: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: 'Este campo es obligatorio.',
            min: 'El número debe tener al menos :min caracteres.',
            max: 'El número no puede tener más de :max caracteres.',
            phone: 'El número debe contener solo dígitos.'
        }
    }));

    const formatPhoneNumber = (value) => {
        // Eliminar cualquier carácter que no sea un dígito
        const cleaned = value.replace(/\D/g, '');
        // Agrupar los dígitos en grupos de 3
        const match = cleaned.match(/(\d{3})(\d{0,3})(\d{0,3})/);
        if (match) {
            return `${match[1]}${match[2] ? ' ' + match[2] : ''}${match[3] ? ' ' + match[3] : ''}`.trim();
        }
        return value;
    };

    const changeHandler = e => {
        const { name, value } = e.target;
        if (name === 'phone') {
            // Formatear el número de teléfono
            const formattedValue = formatPhoneNumber(value);
            setForms({ ...forms, [name]: formattedValue });
        } else {
            setForms({ ...forms, [name]: value });
        }

        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();

        // Validación adicional para el teléfono
        const phoneRegex = /^\d{3} \d{3} \d{3}$/; // Validación para el formato "123 456 789"
        if (!phoneRegex.test(forms.phone)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El número de teléfono debe contener exactamente 9 dígitos y estar en el formato correcto (ej. 123 456 789).',
            });
            return;
        }

        if (validator.allValid()) {
            validator.hideMessages();
            try {
                await sendContact(forms.name, forms.email, forms.phone, forms.message, forms.company)
                .then((result) => {
                    console.log(result);
                    Swal.fire({
                        icon: 'success',
                        title: 'Mensaje enviado!',
                        text: 'Nos contactaremos lo más pronto posible contigo.',
                    });
                });
                setForms({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: ''
                });
            } catch (error) {
                console.log('Hubo un problema al enviar el mensaje. Inténtalo de nuevo más tarde.');
            }
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">Tus nombres</label>
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onChange={changeHandler}
                            placeholder="Goladria Gomez" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">Tu correo</label>
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onChange={changeHandler}
                            placeholder="tuemail@tudominio.com" />
                        {validator.message('email', forms.email, 'required|email ')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">Teléfono</label>
                        <input
                            value={forms.phone}
                            type="text"
                            name="phone"
                            className="form-control"
                            onChange={changeHandler}
                            maxLength={14}  // Limitar a 14 caracteres para incluir espacios
                            placeholder="627 xxx xxx" />
                        {validator.message('phone', forms.phone, 'required')} 
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_textarea">Tu mensaje</label>
                        <textarea
                            onChange={changeHandler}
                            value={forms.message}
                            type="text"
                            name="message"
                            className="form-control"
                            placeholder="Como podemos ayudarte?">
                        </textarea>
                        {validator.message('message', forms.message, 'required')}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        <span className="btn_label" data-text="Enviar mensaje">Enviar mensaje</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;