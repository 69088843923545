import React from 'react'
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import ContactForm from '../ContactFrom/ContactForm'
import shape1 from '../../../images/shapes/shape_line_5.svg'
import shape2 from '../../../images/shapes/shape_line_6.svg'
import shape3 from '../../../images/shapes/shape_space_5.svg'


const ContactSection = (props) => {
    return (
        <section className="contact_section pb-80 bg-light section_decoration">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact_method_box">
                            <div className="heading_block">
                                <div className="heading_focus_text has_underline d-inline-flex mb-3" style={{ backgroundImage: `url(${Bg})` }}>
                                    Estás aquí
                                </div>
                                <h2 className="heading_text mb-0">
                                    Comencemos
                                </h2>
                                <p className="heading_description mb-0">Iniciando tu viaje hacia el éxito y el crecimiento.</p>
                            </div>
                            <ul className="contact_method_list unordered_list_block">
                                <li>
                                    <a href="tel:+34627318514">
                                        <span className="icon">
                                            <i className="fa-solid fa-phone-volume"></i>
                                        </span>
                                        <span className="text">+34 627 318 514</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:soporte@originaltwinsconsulting.com">
                                        <span className="icon">
                                            <i className="fa-solid fa-envelope"></i>
                                        </span>
                                        <small>soporte@originaltwinsconsulting.com</small>
                                    </a>
                                </li>
                                <li>
                                    <a href="#!">
                                        <span className="icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </span>
                                        <span className="text">C/ arenal, 20 -1º dcha 
                                            Madrid - España 28013</span>
                                    </a>
                                </li>
                            </ul>
                            <ul className="support_step unordered_list_block">
                                <li>
                                    <span className="serial_number">01</span>
                                    <span className="text">Comparte tus requisitos</span>
                                </li>
                                <li>
                                    <span className="serial_number">02</span>
                                    <span className="text">Discútelos con nuestros expertos</span>
                                </li>
                                <li>
                                    <span className="serial_number">03</span>
                                    <span className="text">Obtén una cotización gratuita</span>
                                </li>
                                <li>
                                    <span className="serial_number">04</span>
                                    <span className="text">Inicia tu proyecto</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="instant_contact_form">
                            <div className="small_title">
                                <i className="fa-solid fa-envelope-open-text"></i>
                                ¡Conectemos!
                            </div>
                            <h3 className="form_title">
                                Envíanos un mensaje y discutiremos tu proyecto contigo de manera rápida.
                            </h3>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="OT Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="OT Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="OT Shape" />
            </div>
        </section>
    )
}

export default ContactSection;